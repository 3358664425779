import http from "../http-common";

class incidentRegisterService {
  //#region Post Register Incident
  postRegisterIncident(data) {
    return http.post(`/IncidentRegister/PostRegisterIncident`, data);
  }
  //#endregion

  //#region Read Incident Search Values
  readIncidentSearchValues(searchField) {
    return http.get(
      `/IncidentRegister/ReadIncidentsUniqueSearchValuesBySearchField?SearchField=${searchField}`
    );
  }
  //#endregion

  //#region Read Incidents By Search Field and Search Value
  readIncidentsBySearchFieldAndSearchValue(searchField, searchValue) {
    return http.get(
      `/IncidentRegister/ReadIncidentsBySearchFieldAndSearchValue?SearchField=${searchField}&SearchValue=${searchValue}`
    );
  }
  //#endregion

  //#region Read All Incidents
  readAllIncidents() {
    return http.get(`/IncidentRegister/ReadAllIncidents`);
  }
  //#endregion

  //#region Read Incident By Id
  readIncidentById(incidentRegisterID) {
    return http.get(
      `/IncidentRegister/ReadIncidentById?incidentRegisterID=${incidentRegisterID}`
    );
  }
  //#endregion

  //#region Post Update Incident
  postUpdateIncident(data) {
    return http.post(`/IncidentRegister/PostUpdateIncident`, data);
  }
  //#endregion

  //#region Post Delete Incident
  postDeleteIncident(data) {
    return http.post(`/IncidentRegister/PostDeleteIncident`, data);
  }
  //#endregion

  //#region Export Incidents List To Excel
  exportIncidentsListToExcel() {
    return http.get(`/IncidentRegister/ExportIncidentsListToExcel`, {
      responseType: "blob",
    });
  }
  //#endregion

  //#region Post Update Action on Incident
  postUpdateActionOnIncident(data) {
    return http.post(`/IncidentRegister/PostUpdateActionOnIncident`, data);
  }
  //#endregion
}
export default new incidentRegisterService();
