import http from "../http-common";

class incidentTypeService {
  //#region validate and upload noun definitions
  PostAddIncidentType(data) {
    return http.post(`/IncidentType/PostAddIncidentType`, data);
  }
  //#endregion

  //#region Incident Type List
  ReadAllIncidentTypes(IsToFetchOnlyActiveIncidentTypes) {
    return http.get(
      `/IncidentType/ReadAllIncidentTypes?IsToFetchOnlyActiveIncidentTypes=${IsToFetchOnlyActiveIncidentTypes}`
    );
  }
  //#endregion

  //#region Incident Type ID
  readIncidentTypeById(IncidentTypeID) {
    return http.get(
      `/IncidentType/ReadIncidentTypeById?IncidentTypeID=${IncidentTypeID}`
    );
  }
  //#endregion

  //#region validate and upload noun definitions
  postUpdateIncidentType(data) {
    return http.post(`/IncidentType/PostUpdateIncidentType`, data);
  }
  //#endregion

  //#region validate and upload noun definitions
  postDeleteIncidentType(data) {
    return http.post(`/IncidentType/PostDeleteIncidentType`, data);
  }
  //#endregion

  //#region Export Incident Type List to Excel
  exportIncidentTypeListToExcel() {
    return http.get(`/IncidentType/ExportIncidentTypesListToExcel`, {
      responseType: "blob",
    });
  }
  //#endregion
}
export default new incidentTypeService();
