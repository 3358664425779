import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import helper from "../../helpers/helpers";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./IncidentReportNew.scss";
import IncidentType from "./IncidentType";
import RegisterIncident from "./RegisterIncident";
import IncidentReport from "./IncidentReport";
import IncidentReportDashboard from "./IncidentReportDashboard";
import { useHistory, useLocation } from "react-router-dom";

toast.configure();

function IncidentReportMenu() {
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(null);
  const [defaultActiveKey, setDefaultActiveKey] = useState("");
  const [activeKey, setActiveKey] = useState("incidentReportDashboard");
  const [loading] = useState(false);
  const [spinnerMessage] = useState("");

  //#region Tab toggle
  const toggle = (tab, key) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setActiveKey(key);
    }
    setActiveTab(tab);
  };
  //#endregion

  useEffect(() => {
    if (!helper.getUser()) {
      history.push({ pathname: "/" });
      return;
    }
    setActiveTabFromState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#region Set Active Tab
  const setActiveTabFromState = () => {
    const { state } = location;
    if (state) {
      if (state.activeTab === 5) {
        setActiveTab(5);
        setDefaultActiveKey("incidentType");
        setActiveKey("incidentType");
      } else if (state.activeTab === 3) {
        setActiveTab(3);
        setDefaultActiveKey("incidentReport");
        setActiveKey("incidentReport");
      } else if (state.activeTab === 2) {
        setActiveTab(2);
        setDefaultActiveKey("registerIncident");
        setActiveKey("registerIncident");
      } else {
        setActiveTab(1);
        setDefaultActiveKey("incidentReportDashboard");
        setActiveKey("incidentReportDashboard");
      }
    } else {
      setActiveTab(1);
      setDefaultActiveKey("incidentReportDashboard");
      setActiveKey("incidentReportDashboard");
    }
  };
  //#endregion

  //#region return
  return (
    <div className="incidentReportMenuMain">
      <LoadingOverlay
        active={loading}
        className="custom-loader"
        spinner={
          <div className="spinner-background">
            <BarLoader
              css={helper.getcss()}
              color={"#38D643"}
              width={"350px"}
              height={"10px"}
              speedMultiplier={0.3}
            />
            <p style={{ color: "black", marginTop: "5px" }}>{spinnerMessage}</p>
          </div>
        }
      >
        <div>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={defaultActiveKey}
            activeKey={activeKey}
          >
            {activeTab !== 5 && (
              <div
                className="row"
                style={{ marginRight: "15px", marginTop: "0px" }}
              >
                <div>
                  <Nav
                    variant="pills"
                    className="mg-l-40 mg-b-10 mg-t-10"
                    style={{ cursor: "pointer" }}
                  >
                    <Nav.Item>
                      <Nav.Link
                        eventKey="incidentReportDashboard"
                        style={{ border: "1px solid #5E41FC" }}
                        onClick={() => toggle(1, "incidentReportDashboard")}
                      >
                        Incident Report Dashboard
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="registerIncident"
                        style={{ border: "1px solid #5E41FC" }}
                        onClick={() => toggle(2, "registerIncident")}
                      >
                        Register Incident
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="incidentReport"
                        style={{ border: "1px solid #5E41FC" }}
                        onClick={() => toggle(3, "incidentReport")}
                      >
                        Incident Report
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="incidentType"
                        style={{ border: "1px solid #5E41FC" }}
                        onClick={() => toggle(5, "incidentType")}
                      >
                        Incident Type
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="d-flex justify-content-end"></div>
              </div>
            )}
            <Tab.Content>
              <Tab.Pane eventKey="incidentReportDashboard">
                <IncidentReportDashboard />
              </Tab.Pane>
              <Tab.Pane eventKey="registerIncident">
                <RegisterIncident toggle={toggle} setActiveKey={setActiveKey} />
              </Tab.Pane>
              <Tab.Pane eventKey="incidentReport">
                <IncidentReport
                  setActiveTab={setActiveTab}
                  setActiveKey={setActiveKey}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="incidentType">
                <IncidentType />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </LoadingOverlay>
    </div>
  );
  //#endregion
}
export default IncidentReportMenu;
